import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Slider from 'react-slick';
import Slide from './Slide';
import PaginationArrow from './PaginationArrow';
import Utils from '../../utils';

class MediaRelationsSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0,
    };

    this.sliderSettings = {
      autoplay: false,
      centerMode: true,
      centerPadding: '550px',
      dots: false,
      nextArrow: (<PaginationArrow isNext />),
      prevArrow: (<PaginationArrow />),
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            centerPadding: '400px',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            centerPadding: '300px',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 960,
          settings: {
            centerPadding: '200px',
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 864,
          settings: {
            arrows: false,
            centerPadding: '100px',
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerPadding: '50px',
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      afterChange: (current) => this.setState({ activeSlide: current }),
      // beforeChange: (current, next) => this.setState({ activeSlide: next }),
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(index, type, url, videoId, timestamp) {
    const { onSlideClick } = this.props;
    const { activeSlide } = this.state;

    if (index === activeSlide) {
      onSlideClick(type, url, videoId, timestamp);
    }
  }

  render() {
    const { activeSlide } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(
              filter: {
                fileAbsolutePath: {regex: "/(../media-samples)/.*\\.md$/"}
                frontmatter: {
                  example: { ne: true }
                  featureOutCommunicate: { eq: true }
                }
              }
              sort: {order: ASC, fields: [frontmatter___order]}
            ) {
              edges {
                node {
                  id
                  frontmatter {
                    type
                    background {
                      childImageSharp {
                        resize(height: 600) {
                          src
                        }
                      }
                      relativePath
                    }
                    logo {
                      childImageSharp {
                        resize(height: 100, grayscale: true) {
                          src
                        }
                      }
                      relativePath
                    }
                    logoAlt
                    heading
                    subheading
                    timestamp
                    title
                    videoid
                  }
                }
              }
            }
          }
        `}
        render={({ allMarkdownRemark }) => (
          <div>
            <Slider className="ticker--slider" {...this.sliderSettings}>
              {allMarkdownRemark.edges.map(({ node }, index) => {
                const {
                  background,
                  heading,
                  logoAlt,
                  logo,
                  subheading,
                  timestamp,
                  title,
                  type,
                  videoid,
                } = node.frontmatter;
                const bgPath = Utils.getImagePath(background);
                const logoPath = Utils.getImagePath(logo);

                return (
                  <div
                    key={node.id}
                    onClick={() => this.handleClick(index, type, title, videoid, timestamp)}
                    onKeyDown={() => this.handleClick(index, type, title, videoid, timestamp)}
                    role="button"
                    tabIndex="0"
                  >
                    <Slide
                      background={bgPath}
                      current={activeSlide}
                      index={index}
                      logoAlt={logoAlt}
                      logo={logoPath}
                      subtitle={subheading}
                      title={heading}
                      type={type}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
      />
    );
  }
}

MediaRelationsSlider.propTypes = {
  onSlideClick: PropTypes.func.isRequired,
};

export default MediaRelationsSlider;
