import React from 'react';
import MediaRelationsTemplate from 'templates/MediaRelationsTemplate';

const StrategicCommunicationsPage = () => (
  <MediaRelationsTemplate
    metaDescription="OutCommunicate Strategic Communications - Execute strategically and be relevant on the global stage"
    title="Strategic communications"
    url="/strategic-communications"
  >
    <div>
      <div className="container--narrow content">
        <h4 className="tc">PR firms always disappoint.</h4>
        <p className="intro--paragraph">
          The traditional PR business model is broken. It relies on senior executives
          to sell the vision, then push the work down to tactical people who don’t have
          the experience to deliver.
        </p>
        <p>
          With OutVox’s Strategic Communications, you get a team of senior executives
          that own and deliver outcomes. We focus on elevating a story that matters to
          the world and most importantly to the media. We execute a small number of high
          impact campaigns and deliver features in national print and broadcast outlets that
          move markets. These are the outlets that investors and influencers follow—all
          other downstream media is noise that won’t help your brand.
        </p>
        <p>
          Unlike PR firms, we only take on a limited number of accounts. And, because our
          overhead costs are low, the business model works.
        </p>
      </div>
    </div>
  </MediaRelationsTemplate>
);

export default StrategicCommunicationsPage;
