import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import MediaRelationsSlider from 'components/MediaRelationsSlider';
import ModalVideoPlayer from 'components/ModalVideoPlayer';
import TickerCards from 'components/TickerCards';
import ServicesList from 'components/ServicesList';

const bullets = [
  'Execute strategically',
  'Quality over quantity',
  'Be relevant on the global stage',
];

class MediaRelationsTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalVideoId: null,
      modalTimestamp: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleClick(type, url, videoId, timestamp) {
    const { modalIsOpen } = this.state;

    if (type === 'video' && !modalIsOpen) {
      // Open video player in modal
      this.openModal(videoId, timestamp);
    }

    if (type === 'article') {
      // Open article in a new window
      window.open(url, '_blank');
    }
  }

  closeModal() {
    this.setState(() => ({ modalIsOpen: false }));
  }

  openModal(videoId, timestamp) {
    const { modalIsOpen } = this.state;

    if (!modalIsOpen) {
      this.setState(() => ({
        modalIsOpen: true,
        modalVideoId: videoId,
        modalTimestamp: timestamp,
      }));
    }
  }

  render() {
    const {
      children,
      metaDescription,
      title,
      url,
    } = this.props;
    const { modalIsOpen, modalTimestamp, modalVideoId } = this.state;

    return (
      <PageTemplate className="service media-relations" metaDescription={metaDescription}>
        <Helmet title={title} />

        <ModalVideoPlayer
          isOpen={modalIsOpen}
          onClose={this.closeModal}
          timestamp={modalTimestamp}
          videoId={modalVideoId}
        />

        <div className="service--title container">
          <h1>
            Out<strong>Communicate</strong>
          </h1>

          <h2>{title}</h2>
        </div>

        <MediaRelationsSlider onSlideClick={this.handleClick} />

        <div className="container">
          <div className="communications--bullets">
            <ul>
              {/* eslint-disable-next-line react/no-array-index-key */}
              {bullets.map((text, index) => <li key={index}>{text}</li>)}
            </ul>
          </div>
          <TickerCards onCardClick={this.handleClick} />

          {children}

          <ServicesList excludeUrl={url} />
        </div>
      </PageTemplate>
    );
  }
}

MediaRelationsTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  metaDescription: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

MediaRelationsTemplate.defaultProps = {
  metaDescription: null,
};

export default MediaRelationsTemplate;
