import React from 'react';
import PropTypes from 'prop-types';
import DefaultImage from 'images/naphtali-marshall-61123-unsplash.jpg';

const Slide = ({
  background,
  current,
  index,
  logo,
  logoAlt,
  subtitle,
  title,
  type,
}) => {
  const getLogo = () => {
    if (logo) {
      return (
        <img
          className="ticker--slide--logo"
          src={logo}
          alt={logoAlt}
        />
      );
    }

    return null;
  };

  const getAction = () => {
    if (type === 'video') {
      return (
        <div className="play--btn shadow">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
            <g fill="none">
              <circle cx="30" cy="30" r="30" fill="#E1D6C1" />
              <polygon fill="#222" points="24 18 24 42 41.6 29.5" />
            </g>
          </svg>
        </div>
      );
    }

    return null;
  };

  const getContent = () => {
    if (current === index) {
      return (
        <div className="ticker--slide--container">
          {getLogo()}

          {getAction()}

          <div className="ticker--slide--title">
            <p>
              {title || null}
            </p>

            <h3>
              {subtitle}
            </h3>
          </div>
        </div>
      );
    }

    return (
      <div className="ticker--slide--container">
        {getLogo()}
      </div>
    );
  };

  return (
    <div
      className="ticker--slide"
      style={{ backgroundImage: `url(${background || DefaultImage})` }}
    >
      <div className="ticker--slide--overlay">
        {getContent()}
      </div>
    </div>
  );
};

Slide.propTypes = {
  background: PropTypes.string,
  current: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  logoAlt: PropTypes.string,
  logo: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

Slide.defaultProps = {
  background: DefaultImage,
  logo: null,
  logoAlt: '',
  subtitle: null,
};

export default Slide;
